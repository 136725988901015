@import url('https://fonts.googleapis.com/css2?family=Syne&family=Syne+Mono&display=swap');

body {
  font-family: 'Syne', sans-serif;
}
textarea {
  font-family: 'Syne Mono', monospace;
}

textarea.scroll {
  overflow: scroll;
  white-space: pre;
}
